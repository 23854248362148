// https://codesandbox.io/s/04vvrxj79p?file=/src/index.js:1296-1626
import React, { useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

import { useStaticQuery, graphql } from 'gatsby';
import {
  RightToLeftTabs, Opinion, LandscapeCard, PortraitCard, LandscapeCardList, PortraitCardList,
} from '../../components';

import BlogCategoriesList from '../../components/TabletDisclosure/blog-categories-list';
import useIntersectionObserver from './useIntersectionObserver.tsx';
import StickyHeader from './StickyHeader';

import LatestNewsLandscape from './Sections/LatestNewsLandscape';
import LatestNewsPortrait from './Sections/LatestNewsPortrait';
import Video from './Sections/Video';
import Vertical from '../../components/EmotionCard/Vertical';
import Underlined from '../../components/EmotionCard/Underlined';
import RowOf4Cards from './Sections/RowOf4Cards';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  contentWithMargin: {
    /* marginLeft: '1.5rem',
    marginRight: '1.5rem', */ // RTL
    // backgroundColor: 'orange',
    minWidth: '100%',
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const buildThresholdList = () => {
  const thresholds = [];
  const numSteps = 50;

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};

const threshold = buildThresholdList();

const IntersectBox = (props) => {
  const {
    title, anchor, settitle, root, selected, backgroundcolor,
  } = props;

  const rootMargin = '-64px 0px -20px 0px';
  const callback = () => {
    // console.log('called');
  };

  const [ref, { entry }] = useIntersectionObserver(callback, { root, rootMargin, threshold });
  const isAbove = entry && entry.boundingClientRect.y < entry.rootBounds.y;
  const isVisible = entry && entry.isIntersecting;
  useEffect(() => {
    if (entry) {
      settitle(title, anchor, ref, entry, backgroundcolor);
    }
  }, [entry, ref]);

  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  );
};

IntersectBox.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  anchor: PropTypes.string,
  settitle: PropTypes.func,
  root: PropTypes.func,
  selected: PropTypes.bool,
  backgroundcolor: PropTypes.string,
};

const HomePage = () => {
  const data = useStaticQuery(graphql`
    {
       books: allResourcePost(filter: {category: {eq: "books"}}, limit: 2) {
        edges {
          node {
            id
            title
            locale
            date
            timeToRead
            body
            authorId
            category
            description
            excerpt
            slug
            ... on MdxResourcePost {
              featuredImage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }

      culture: allResourcePost(filter: {category: {eq: "culture"}}, limit: 1) {
       edges {
         node {
           id
           title
           locale
           date
           timeToRead
           body
           authorId
           category
           description
           excerpt
           slug
           ... on MdxResourcePost {
             featuredImage {
               childImageSharp {
                 fluid {
                   ...GatsbyImageSharpFluid
                 }
               }
             }
           }
         }
       }
     }

     art: allResourcePost(filter: {category: {eq: "art"}}, limit: 4) {
      edges {
        node {
          id
          title
          locale
          date
          timeToRead
          body
          authorId
          category
          description
          excerpt
          slug
          ... on MdxResourcePost {
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }


    caricature: allResourcePost(filter: {category: {eq: "caricature"}}, limit: 1) {
     edges {
       node {
         id
         title
         locale
         date
         timeToRead
         body
         authorId
         category
         description
         excerpt
         slug
         ... on MdxResourcePost {
           featuredImage {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid
               }
             }
           }
         }
       }
     }
   }


   science: allResourcePost(filter: {category: {eq: "science"}}, limit: 1) {
    edges {
      node {
        id
        title
        locale
        date
        timeToRead
        body
        authorId
        category
        description
        excerpt
        slug
        ... on MdxResourcePost {
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }

  news: allResourcePost(filter: {category: {eq: "news"}}, limit: 4) {
   edges {
     node {
       id
       title
       locale
       date
       timeToRead
       body
       authorId
       category
       description
       excerpt
       slug
       ... on MdxResourcePost {
         featuredImage {
           childImageSharp {
             fluid {
               ...GatsbyImageSharpFluid
             }
           }
         }
       }
     }
   }
 }

    }
  `);

  const classes = useStyles();
  const [title, setTitle] = useState('default');
  const [color, setColor] = useState('default');
  const [ankor, setAnkor] = useState('default');
  const [triggered, setTriggered] = useState({
    trigger: [], color: [], anchor: [], triggered: false,
  });
  const [entries, setEntries] = useState([]);
  const [rooti, setRooti] = useState({ current: undefined });
  // const rooti = { current: undefined };
  useEffect(() => {
    setRooti({ current: document.getElementById('StickyHeader') });
  }, []);

  const callback = (tittle, anchor, ref, entry, colour) => {
    const isAbove = entry && entry.boundingClientRect.y < entry.rootBounds.y;

    const { wasAbove } = entries[tittle] ? entries[tittle] : { wasAbove: false };
    if (isAbove && !wasAbove) {
      setTitle(tittle);
      setColor(colour);
      setAnkor(anchor);
      setTriggered({
        trigger: [...triggered.trigger, tittle],
        color: [...triggered.color, colour],
        anchor: [...triggered.anchor, anchor],
        triggered:
         true,
      });
      entries[tittle] = { ratio: entry.intersectionRatio, wasAbove: isAbove };
      setEntries(entries);
    } else if (wasAbove && !isAbove) {
      const popped = triggered.trigger && triggered.trigger.pop ? triggered.trigger.pop() : triggered.trigger;
      const poppedColor = triggered.color && triggered.color.pop ? triggered.color.pop() : triggered.color;
      const poppedAnchor = triggered.anchor && triggered.anchor.pop ? triggered.anchor.pop() : triggered.anchor;

      setTitle(triggered.trigger.length > 0 ? triggered.trigger[triggered.trigger.length - 1] : tittle);
      setColor(triggered.color.length > 0 ? triggered.color[triggered.color.length - 1] : colour);
      setAnkor(triggered.anchor.length > 0 ? triggered.anchor[triggered.anchor.length - 1] : anchor);
      setTriggered({
        trigger: triggered.trigger, color: triggered.color, anchor: triggered.anchor, triggered: triggered.trigger.length > 0,
      });
      entries[tittle] = { ratio: entry.intersectionRatio, wasAbove: isAbove };
      setEntries(entries);
    }
  };

  return (
    <div id="StickyHeader">
      <StickyHeader title={title} href={`${ankor}`} backgroundcolor={color} triggered={triggered.triggered} />

      <Hidden only={['md', 'lg', 'xl']}>

        <Grid container>
          <Grid item xs={12}><Underlined /></Grid>
          <Grid item xs={12}><RowOf4Cards posts={data.news.edges} /></Grid>
          <Grid item xs={12} md={8}>

            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="LatestNews" id="LatestNews" title="آخر الأخبار" root={rooti.curent} selected={title === 'title1'} backgroundcolor="firebrick">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'firebrick',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                آخر الأخبار
              </div>
            </IntersectBox>
            <LatestNewsPortrait posts={data.news.edges} />
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12} className={classes.contentWithMargin}>
                <Opinion />
              </Grid>

              <Grid item xs={12} className={classes.contentWithMargin}>
                <CssBaseline />
                <RightToLeftTabs />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Culture" id="Culture" title="ثقافة" root={rooti.curent} selected={title === 'علوم'} backgroundcolor="indigo">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'indigo',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                ثقافة
              </div>

            </IntersectBox>
            <LatestNewsLandscape posts={data.culture.edges} />
          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Science" id="Science" title="علوم" root={rooti.curent} selected={title === 'علوم'} backgroundcolor="dimgrey">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'dimgrey',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                علوم
              </div>

            </IntersectBox>

            <LatestNewsLandscape posts={data.science.edges} />

          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>

            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Caricature" id="Caricature" title="كاريكاتير" root={rooti.curent} selected={title === 'كاريكاتير'} backgroundcolor="cornflowerblue">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'cornflowerblue',
                  color: 'white',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                كاريكاتير
              </div>

            </IntersectBox>

            <LatestNewsLandscape posts={data.caricature.edges} />
            <LatestNewsLandscape posts={data.caricature.edges} />

          </Grid>
          {/* <Grid item xs={12}><Underlined /></Grid>
          <LatestNewsLandscape posts={data.books.edges} />
          <Grid item xs={12}><Underlined /></Grid> */}
          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Art" id="Art" title="فن" root={rooti.curent} selected={title === 'فن'} backgroundcolor="crimson">
              <div
                variant="h6"
                style={{
                  backgroundColor: 'crimson',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                فن
              </div>
            </IntersectBox>
            <LatestNewsLandscape posts={data.art.edges} />

          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Books" id="Books" title="كتب" root={rooti.curent} selected={title === 'كتب'} backgroundcolor="darkslategray">
              <div
                variant="h6"
                style={{
                  backgroundColor: 'darkslategray',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                كتب
              </div>
            </IntersectBox>
            <LatestNewsLandscape posts={data.books.edges} />

          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Videos" id="Videos" title="فيديوهات" root={rooti.curent} selected={title === 'فيديوهات'} backgroundcolor="darkcyan">
              <div
                variant="h6"
                style={{
                  backgroundColor: 'darkcyan',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                فيديوهات
              </div>
            </IntersectBox>
            <Video />

          </Grid>

        </Grid>

      </Hidden>
      <Hidden only={['xs', 'sm']}>
        <Grid container>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={8}>
            <LatestNewsPortrait posts={data.books.edges} />
          </Grid>

          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12} className={classes.contentWithMargin}>
                <Opinion />
              </Grid>

              <Grid item xs={12} className={classes.contentWithMargin}>
                <CssBaseline />
                <RightToLeftTabs />
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>

            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="LatestNews" id="LatestNews" title="آخر الأخبار" root={rooti.curent} selected={title === 'title1'} backgroundcolor="firebrick">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'firebrick',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                آخر الأخبار
              </div>
            </IntersectBox>
            <RowOf4Cards posts={data.news.edges} />
          </Grid>
          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Culture" id="Culture" title="ثقافة" root={rooti.curent} selected={title === 'علوم'} backgroundcolor="indigo">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'indigo',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                ثقافة
              </div>

            </IntersectBox>
            <LatestNewsLandscape posts={data.culture.edges} />
          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Science" id="Science" title="علوم" root={rooti.curent} selected={title === 'علوم'} backgroundcolor="dimgrey">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'dimgrey',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                علوم
              </div>

            </IntersectBox>

            <LatestNewsLandscape posts={data.science.edges} />

          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>

            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Caricature" id="Caricature" title="كاريكاتير" root={rooti.curent} selected={title === 'كاريكاتير'} backgroundcolor="cornflowerblue">

              <div
                variant="h6"
                style={{
                  backgroundColor: 'cornflowerblue',
                  color: 'white',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                كاريكاتير
              </div>

            </IntersectBox>

            <LatestNewsLandscape posts={data.caricature.edges} />
            <Underlined />
            <LatestNewsLandscape posts={data.caricature.edges} />

          </Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Art" id="Art" title="فن" root={rooti.curent} selected={title === 'فن'} backgroundcolor="crimson">
              <div
                variant="h6"
                style={{
                  backgroundColor: 'crimson',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                فن
              </div>
            </IntersectBox>
            <LatestNewsLandscape posts={data.art.edges} />

          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Books" id="Books" title="كتب" root={rooti.curent} selected={title === 'كتب'} backgroundcolor="darkslategray">
              <div
                variant="h6"
                style={{
                  backgroundColor: 'darkslategray',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                كتب
              </div>
            </IntersectBox>
            <LatestNewsLandscape posts={data.books.edges} />

          </Grid>
          <Grid item xs={12}><Underlined /></Grid>

          <Grid item xs={12}>
            <IntersectBox settitle={(title, anchor, ref, entry, backgroundColor) => { callback(title, anchor, ref, entry, backgroundColor); }} anchor="Videos" id="Videos" title="فيديوهات" root={rooti.curent} selected={title === 'فيديوهات'} backgroundcolor="darkcyan">
              <div
                variant="h6"
                style={{
                  backgroundColor: 'darkcyan',
                  color: 'white',
                  fontSize: '2rem',
                  minHeight: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '8px',
                  fontFamily: 'Almarai',
                }}
              >
                فيديوهات
              </div>
            </IntersectBox>
            <Video />

          </Grid>

        </Grid>

      </Hidden>

    </div>
  );
};

export default HomePage;
